import React from 'react'
import PropTypes from 'prop-types'
import { GvtAvatar, GvtCard, GvtCardContent, GvtCardHeader, GvtTypography } from '@gravity/ui-components'
import { useTheme } from '@mui/material';

export const PendingApprovalCard = ({
  foregroundColor,
  cardWidth,
  title,
  titleSize,
  testId,
  approvalCount,
  icon,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <GvtCard style={{backgroundColor: approvalCount > 0 ? theme.status.fair : '', color: foregroundColor}}>
      <GvtCardHeader titleTypographyProps={{style: {color: foregroundColor}}} avatar={<GvtAvatar sx={{ bgcolor: 'transparent', width: 34, height: 34 }} variant="square">{icon}</GvtAvatar>} title={title} subheader='' />
      <GvtCardContent>
        <GvtTypography variant='h3'>{approvalCount ? approvalCount : 0}</GvtTypography>
      </GvtCardContent>
    </GvtCard>
  )
}

PendingApprovalCard.propTypes = {
  /**
   * Foreground color will be applied only on the title and the card body
   * Button color should be handled inside the button with <Typography> component
   */
  foregroundColor: PropTypes.string,
  /**
   * This can be either 'small' or 'medium'
   */
  cardWidth: PropTypes.string,
  /**
   * This property is mandatory and will be used for displaying the title of the card
   * This is mandatory.
   */
  title: PropTypes.string.isRequired,
  /**
   * This property will be used for displaying the title size
   */
  titleSize: PropTypes.string,
  /**
   * It will be used for component reference to test.
   * This is mandatory.
   */
  testId: PropTypes.string.isRequired,
  /**
   * This will be total of pending approvals 
   */
   approvalCount: PropTypes.number,
   /**
   * This will be an icon component
   */
  icon: PropTypes.element
}

PendingApprovalCard.defaultProps = {
  foregroundColor: '',
  cardWidth: 'medium',
  titleSize: 'small',
  approvalCount: 0,
  icon: <></>
}
