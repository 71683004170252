import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import useAdpReducer from './reducer'

const AdpContext = React.createContext()
const PendingContext = React.createContext()

export const AdpContextProvider = ({ children }) => {
  const stateAndDispatch = useAdpReducer()
  return (
    <AdpContext.Provider value={stateAndDispatch}>
      {children}
    </AdpContext.Provider>
  )
}

export const PendingContextProvider = ({ children }) => {
  const pendingRef = useRef(true);
  return (
    <PendingContext.Provider value={{ pendingRef }}>
      {children}
    </PendingContext.Provider>
  )

}

AdpContextProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export const useAdpContext = () => {
  const { AdpState, dispatchAdpContext } = React.useContext(
    AdpContext
  )
  return { ...AdpState, dispatchAdpContext }
}

export const usePendingContext = () => {
  return React.useContext(PendingContext);
}
