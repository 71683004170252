import { DecoratedFormControl, GvtModalDialog, px2rem, RightContent } from "@gravity/ui-components";
import { Tooltip, tooltipClasses } from "@mui/material";
import styled from "styled-components";

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiFormControl-root:not(:first-child):not(:second-child) {
    padding-top: 12px !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const StyledColumnLayout = styled.div`
  div:empty {
    padding: 0px !important;
    display: none !important;
    width: 0px !important;
  }

  /* .MuiGrid-item {
    padding-left: ${px2rem(24)};
  } */
`;

export const StyledWizard = styled.div`
  height: 100%;

  > div:first-child {
    height: ${px2rem(72)};
  }

  > *:not(:first-child) {
    height: calc(100% - ${px2rem(72)});
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const StyledWizardHeader = styled.div`
  height: ${px2rem(72)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--chart-category-other);
`;

export const StyledWizardContent = styled.div`
  height: calc(100% - ${px2rem(72)});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${px2rem(18, 78, 36, 78)};
`;

export const StyledWizardFooter = styled.div`
  height: ${px2rem(62)};
  > .actions {
    display: flex;
    justify-content: end;
  
    .auto_save{
      margin-top:5px;
      animation: blinker 2s linear infinite;
      margin-right: 10px;
      color: var(--brand-hpeGreen) !important;
      
      .in_progress_icon{
        margin-right:5px;
      }
    }

    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }
  }
`;

export const StyledWizardBody = styled.div`
  height: calc(100% - ${px2rem(62)});
  overflow: auto;
`;

export const StyledAccordionTitle = styled.div`
  display: flex;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
`;

export const StyledDecoratedFormControl = styled(DecoratedFormControl)`
  width: 100% !important;
  max-width: unset !important;

  .rich-text-editor {
    width: 21.333333333333332rem;
    border: 0.05555555555555555rem solid var(--border-default);
    border-radius: 0.2222222222222222rem;
  }
`;

export const StyledSimpleTable = styled.div`
  position: relative;

  .has-error {
    .table-container {
      background-color: var(--validation-error) !important;
    }
  }

  .has-error-self {
    background-color: var(--validation-error) !important;
  }
`;

export const StyledSimpleTableAdd = styled.div`
  position: absolute;
  z-index: 999;
  right: 0;
  top: -20px;
`;

export const StyledWizardStatus = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: ${px2rem(96)};

  > *:not(:first-child) {
    margin-top: ${px2rem(15)};
  }

  .actions {
    display: flex;
    > *:not(:first-child) {
      margin-left: ${px2rem(10)};
    }
  }
`;

export const StyledReviewIcon = styled.div`
  height: 100px;
  width: 100px;
  cursor: pointer;
  > img {
    height: 100px;
    width: 100px;
  }
`;

export const StyledReview = styled.div`
  > *:first-child {
    margin-bottom: 5px;
  }
`;

export const StyledRequestStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > *:not(:first-child) {
    padding-top: 12px !important;
  }

  > *:first-child {
    padding-top: 10% !important;
  }
`;

export const StyledToggle = styled.div`
  font-size: ${px2rem(14)} !important;

  p {
    font-size: ${px2rem(14)} !important;
  }
`;

export const StyledSubmit = styled.div`
  display: flex;
  justify-content: end;

  buttom {
    margin: none !important;
  }
`;

export const StyledTermsAndConditions = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid #999999;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.04);
  padding: 6px 12px;

  .MuiCheckbox-root {
    margin-right: 10px !important;
  }
`;

export const StyledPage = styled(RightContent)`
  padding: ${px2rem(36, 78, 36, 78)};
  height: 100%;
`;

export const StyledAppCardContent = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 48px;
`;

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  pointerEvents: 'unset !important',
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'unset'
  },
}));

export const StyledDivSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledCardWrapper = styled.div`
  cursor: "pointer";
  height: "100%";
`;

export const StyledGvtModalDialog = styled(GvtModalDialog)`
  & .MuiDialog-container {
    & .MuiPaper-root {
      min-width: ${px2rem(456)}
    }
  }
` 

export const StyledAgreementCheckbox = styled.div`
  margin: .5rem 0;
  padding:.5rem;
  justify-content:start;
  border:1px solid rgba(0, 0, 0, 0.12);

  .MuiFormLabel-root{
    a{
      text-decoration: underline;
      cursor: pointer;
    }   
  }

  .MuiCheckbox-root{
    margin-right:.5rem;
  }
`