import { useReducer } from 'react'

import AdpActions from './actions'

const initialContext = {
  user: {
    groups: [],
    username: null
  },
  organization: {
    name: "HPE",
    state: "APPROVED"
  }
}

const AdpReducer = (state, action) => {
  switch (action.type) {
    case AdpActions.SET_USER:
      return { ...state, user: action.data }
    case AdpActions.SET_ORGANIZATION:
      return { ...state, organization: action.data }
    default:
      return state
  }
}

const useAdpReducer = () => {
  const [AdpState, dispatchAdpContext] = useReducer(
    AdpReducer,
    initialContext
  )
  return { AdpState, dispatchAdpContext }
}

export default useAdpReducer
