import React from 'react'
import PropTypes from 'prop-types'
import { GvtAvatar, GvtCard, GvtCardActions, GvtCardContent, GvtCardHeader } from '@gravity/ui-components'

export const Card = ({
  background,
  foregroundColor,
  cardWidth,
  icon,
  title,
  titleSize,
  description,
  descriptionProps,
  action,
  testId,
  footerContent,
  footerProps,
  ...rest
}) => {
  return (
    <GvtCard style={{backgroundColor: background, color: foregroundColor}}>
      <GvtCardHeader titleTypographyProps={{style: {color: foregroundColor}}} avatar={<GvtAvatar sx={{ bgcolor: 'transparent', width: 34, height: 34 }} variant="square">{icon}</GvtAvatar>} title={title} subheader='' />
      <GvtCardContent>
        {description}
      </GvtCardContent>
      <GvtCardActions sx={{ justifyContent: 'flex-end' }}>
        {action}
      </GvtCardActions>
    </GvtCard>
  )
}

Card.propTypes = {
  /**
   * This background color code will be used for card background
   */
  background: PropTypes.string,
  /**
   * Foreground color will be applied only on the title and the card body
   * Button color should be handled inside the button with <Typography> component
   */
  foregroundColor: PropTypes.string,
  /**
   * This can be either 'small' or 'medium'
   */
  cardWidth: PropTypes.string,
  /**
   * This will be an icon component
   */
  icon: PropTypes.element,
  /**
   * This property is mandatory and will be used for displaying the title of the card
   * This is mandatory.
   */
  title: PropTypes.string.isRequired,
  /**
   * This property will be used for displaying the title size
   */
  titleSize: PropTypes.string,
  /**
   * Description of the card, up to a small paragraph; distinguished from Tile
   * with this text length difference among other reasons
   * This is mandatory.
   */
  description: PropTypes.string.isRequired,
  /**
   * This will be the action button will be placed at the bottom of the card
   */
  action: PropTypes.element,
  /**
   * It will be used for component reference to test.
   * This is mandatory.
   */
  testId: PropTypes.string.isRequired,
  /**
   * This will be the footer content
   */
  footerContent: PropTypes.element,
  /**
   * This will be the footer props
   */
  footerProps: PropTypes.object,
  /**
   * This will be the description props
   */
  descriptionProps: PropTypes.object
}

Card.defaultProps = {
  background: '',
  foregroundColor: '',
  cardWidth: 'medium',
  icon: undefined,
  titleSize: 'medium',
  action: undefined,
  footerContent: undefined,
  footerProps: {},
  descriptionProps: {}
}