import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteAppVersion, getAppSecurityDetails, getApps, putAppApprove, putAppReject, putAppReview, putAppSuspend } from '../../utils/apis';
import { FieldTypesEnum, GvtCard, GvtCardHeader, GvtFormControl, GvtFormLabel, GvtInput, GvtModalDialog, GvtTypography, Loading, MultiValueField, SimpleTable, TableView, ViewModeEnum } from '@gravity/ui-components';
import styled from 'styled-components';
import { Icon, IconsEnum } from '@gravity/icons';
import { appRedirectRoute } from '../../utils/routes';
import { usePendingContext } from '../../context/adp-context/context';

const Modal = forwardRef((props, ref) => {
  const [appActionLoading, setAppActionLoading] = useState(false);
  const [appActionStatus, setAppActionStatus] = useState(true);
  const [appActionReason, setAppActionReason] = useState("");
  const [appAction, setAppAction] = useState({
    action: null,
    data: {}
  });

  const disabledRef = useRef(false);
  const { t } = useTranslation(['apps', 'common']);

  useImperativeHandle(ref, () => ({
    doSetAppAction(type = null, data = {}) {
      setAppAction({
        action: type,
        data: data
      })
      if (type === null) {
        setAppActionReason("")
      }
    }
  }));


  const getLayerTtitle = () => {
    switch (appAction.action) {
      case 'approve':
        return t('approve_app.title');
      case 'reject':
        return t('reject_app.title');
      case 'suspend':
        return t('suspend_app.title');
      case 'review':
        return t('review_app.title');
      case 'delete':
        return t('delete_app.title');
    }
  }

  const getLayerText = () => {
    let info = { name: appAction.data.name, version: appAction.data.version }
    let info2 = { state: appAction.data.status}
    switch (appAction.action) {
      case 'approve':
        if(appAction.data.status === 'PENDING_DRAFT' || appAction.data.status === 'REJECTED_DRAFT' || appAction.data.status === 'SUSPENDED') {
          disabledRef.current = false;
          return appActionStatus ? t('approve_app.confirm', info) :
            t('approve_app.error', info);
        }
        disabledRef.current = true;
        return t('approve_app.info', info2);
      case 'reject':
        if(appAction.data.status === 'PENDING_DRAFT' || appAction.data.status === 'APPROVED_APP' || appAction.data.status === 'SUSPENDED') {
          disabledRef.current = false;
          return appActionStatus ? t('reject_app.confirm', info) :
            t('reject_app.error', info);
        }
        disabledRef.current = true;
        return t('reject_app.info', info2);
      case 'suspend':
        if(appAction.data.status === 'APPROVED_APP') {
          disabledRef.current = false;
          return appActionStatus ? t('suspend_app.confirm', info) :
            t('suspend_app.error', info);
        }
        disabledRef.current = true;
        return t('suspend_app.info', info2);
      case 'review':
        return appActionStatus ? t('review_app.confirm', info) :
          t('review_app.error', info);
      case 'delete':
        return appActionStatus ? t('delete_app.confirm', info) :
          t('delete_app.error', info);
    }
  }


  const doAppAction = (actionType, actionData) => {
    setAppActionLoading(true);
    if (actionType === 'approve') {
      approveApp(actionData);
    } else if (actionType === 'reject') {
      rejectApp(actionData);
    } else if (actionType === 'suspend') {
      suspendApp(actionData);
    } else if (actionType === 'review') {
      reviewApp(actionData);
    } else if (actionType === 'delete') {
      deleteApp(actionData);
    }
  }

  const approveApp = (data) => {
    handleRequest('approve', putAppApprove({ app_id: data.app_id, version: data.version, reason: appActionReason }))
  }

  const rejectApp = (data) => {
    handleRequest('reject', putAppReject({ app_id: data.app_id, version: data.version, reason: appActionReason }))
  }

  const suspendApp = (data) => {
    handleRequest('suspend', putAppSuspend({ app_id: data.app_id, version: data.version, reason: appActionReason }))
  }

  const reviewApp = (data) => {
    handleRequest('review', putAppReview({ app_id: data.app_id, version: data.version, reason: appActionReason }))
  }

  const deleteApp = (data) => {
    deleteAppVersion({ app_id: data.app_id, version: data.version, developer_id: data.developer_id }).then(
      (response) => {
        setAppActionLoading(false);
        setAppActionStatus(true);
        ref.current.doSetAppAction();
        props.updateApp('delete', response.data);
      },
      (error) => {
        console.error(error);
        setAppActionLoading(false);
        setAppActionStatus(false);
      }
    )
  }

  const handleRequest = (action, request) => {
    request.then(
      (response) => {
        props.updateApp(action, response.data);
        setAppActionLoading(false);
        setAppActionStatus(true);
        ref.current.doSetAppAction();
      },
      (error) => {
        console.error(error);
        setAppActionLoading(false);
        setAppActionStatus(false);
      }
    )
  }

  return (
    <GvtModalDialog
      open={appAction.action != null}
      onBackdropClick={() => ref.current.doSetAppAction()}
      onClose={() => ref.current.doSetAppAction()}
      title={getLayerTtitle()}
      description={getLayerText()}
      buttons={
        [{
          onClick: () => { ref.current.doSetAppAction() },
          children: t('cancel', { ns: 'common' }),
          disabled: appActionLoading
        }, {
          onClick: () => { doAppAction(appAction.action, appAction.data) },
          color: 'primary',
          children: appActionStatus ? t('confirm', { ns: 'common' }) : t('retry', { ns: 'common' }),
          disabled: appActionLoading || disabledRef.current
        }]
      }
    >
      {!appActionLoading && !disabledRef.current && appAction.action && appAction.action !== 'delete' && (
        <GvtFormControl>
          <GvtFormLabel htmlFor='reason-input'>{t('reason_for_status_change')}</GvtFormLabel>
          <GvtInput id='reason-input'
            onChange={(e) => {
              setAppActionReason(e.target.value)
            }} />
        </GvtFormControl>
      )}
      {appActionLoading && (
        <Loading />
      )}
    </GvtModalDialog>
  )
})

const SecurityDetailsModal = (props) => {
  const { t } = useTranslation(['apps', 'common']);
  const [rowsData, setRowsData] = useState([]);
  const [multipleDevices, setMultipleDevices] = useState(false);
  const [deviceType, setDeviceType] = useState("");

  const deviceTypeEnum = {
    AP_Image: "AP_Image",
    DC_Image: "DC_Image"
  }

  useEffect(() => {
    if(!props.open) {
      return;
    }
    getAppSecurityDetails(props?.appId, props?.appVersion).then(response => {
      if(Object.keys(response.data).length > 1) {
        setMultipleDevices(true)
        const resp = response.data;
        let key1 = deviceTypeEnum.AP_Image;
        let key2 = deviceTypeEnum.DC_Image;
        const data = [
          createRowsWithTwoCounts(t("app_security_details.critical", { ns: 'apps' }), resp[key1].critical, resp[key2].critical),
          createRowsWithTwoCounts(t("app_security_details.high", { ns: 'apps' }), resp[key1].high, resp[key2].high),
          createRowsWithTwoCounts(t("app_security_details.medium", { ns: 'apps' }), resp[key1].medium, resp[key2].medium),
          createRowsWithTwoCounts(t("app_security_details.low", { ns: 'apps' }), resp[key1].low, resp[key2].low),
          createRowsWithTwoCounts(t("app_security_details.unknown", { ns: 'apps' }), resp[key1].unknown, resp[key2].unknown)
        ];
        setRowsData(data);
      } else if(Object.keys(response.data).length === 1) {
        const resp = response.data;
        let key = Object.keys(resp)[0];
        setDeviceType(key);
        console.log(resp[key]);
        const data = [
          createRows(t("app_security_details.critical", { ns: 'apps' }), resp[key].critical),
          createRows(t("app_security_details.high", { ns: 'apps' }), resp[key].high),
          createRows(t("app_security_details.medium", { ns: 'apps' }), resp[key].medium),
          createRows(t("app_security_details.low", { ns: 'apps' }), resp[key].low),
          createRows(t("app_security_details.unknown", { ns: 'apps' }), resp[key].unknown)
        ];
        setRowsData(data);
      }
    }).catch(error => {
        console.error(error);
      })
    }, [props?.appId, props?.appVersion, props?.open, multipleDevices, deviceType])
  

  const onClose = (e, reason) => {
    setRowsData([]);
    if(reason !== 'backdropClick') {
      props.onClose(false);
    }
  }

  const createRows = (vulnerability, count) => {
    return { vulnerability, count };
  }

  const createRowsWithTwoCounts = (vulnerability, count1, count2) => {
    return { vulnerability, count1, count2 };
  }

  const getVulnerabilityIcon = (input) => {
    switch(input) {
      case 'Critical':
        return IconsEnum.SeveritySmallCritical
      case 'High':
        return IconsEnum.SeveritySmallMinor
      case 'Medium':
        return IconsEnum.SeveritySmallMajor
      case 'Low':
        return IconsEnum.HealthSmallGood
      case 'Unknown':
        return IconsEnum.SeveritySmallUnknown
    }
    return '';
  }

  return (
    
    <GvtModalDialog
      open={props.open}
      onClose={(e) => onClose(e)}
    >
      <GvtCardHeader title={t("app_security_details.title", { ns: 'apps' })} style={{marginTop: "-7%", marginBottom: "5%"}} />
      {multipleDevices && (
        <SimpleTable
          columns={
            [
              {
                field: 'vulnerability',
                displayName: t("app_security_details.vulnerability", { ns: 'apps' })
              },
              {
                field: 'count1',
                displayName: t("app_security_details.ap", { ns: 'apps' }),
                icon: (rowData) => {
                  return getVulnerabilityIcon(rowData?.vulnerability);
                }
              },
              {
                field: 'count2',
                displayName: t("app_security_details.dc", { ns: 'apps' }),
                icon: (rowData) => {
                  return getVulnerabilityIcon(rowData?.vulnerability);
                }
              }
            ]
          }
          rows={rowsData}
        />
      )} 
      {!multipleDevices && (
        <SimpleTable
          columns={
            [
              {
                field: 'vulnerability',
                displayName: t("app_security_details.vulnerability", { ns: 'apps' })
              },
              {
                field: 'count',
                displayName: deviceType === deviceTypeEnum.AP_Image ? t("app_security_details.ap", { ns: 'apps' }) : t("app_security_details.dc", { ns: 'apps' }),
                icon: (rowData) => {
                  return getVulnerabilityIcon(rowData?.vulnerability);
                }
              }
            ]
          }
          rows={rowsData}
        />
      )}
      
    </GvtModalDialog>
  )
}

const AppsPage = () => {

  const { t } = useTranslation(['apps', 'common']);
  const grid = useRef(null);

  const dialogRef = useRef();
  const { pendingRef } = usePendingContext();

  const [modalOpen, setModalOpen] = useState(false);
  const [appId, setAppId] = useState(null);
  const [appVersion, setAppVersion] = useState(null);

  const fetchGridData = (paginationState, searchValue, quickFilters, sorting, filters) => {
    let parsedSort = {}
    if ('fields' in sorting && sorting['fields'].length > 0) {
      for (let i = 0; i < sorting['fields'].length; i++) {
        parsedSort[sorting['fields'][i]['field']] = sorting['fields'][i]['order'].toLowerCase() === 'asc' ? 1 : -1;
      }
    }

    let parsedFilter = {};
    if(pendingRef.current) {
      parsedFilter['status'] = [AppStatus.PENDING_DRAFT];
      parsedFilter['status'].push(AppStatus.IN_REVIEW);
    }

    let currentFilter = filters.filters.filters && filters.filters.filters.length >= 1 && 'filters' in filters.filters.filters[0] ? filters.filters.filters[0]['filters'] : filters.filters.filters;
    for (let i = 0; i < currentFilter.length; i++) {
      if (!(currentFilter[i]['field'] in parsedFilter)) {
        parsedFilter[currentFilter[i]['field']] = [];
      }
      parsedFilter[currentFilter[i]['field']].push(currentFilter[i]['value'])
      pendingRef.current = false;
    }

    return getApps({ 
      pageNumber: (paginationState.offset / paginationState.limit) + 1, 
      pageSize: paginationState.limit, 
      sort: parsedSort, 
      query: parsedFilter
    }).then(
      (response) => {
        return {
          rows: response.data.content,
          total: response.data.total_elements,
          totalFiltered: response.data.number_of_elements
        }
      },
      (error) => {
        console.error(error);
        return {
          rows: [],
          total: 0,
          totalFiltered: 0
        }
      }
    )
  };

  const updateApp = (action, data) => {
    if (action == 'delete') {
      grid.current.api.purgeInfiniteCache();
    } else {
      grid.current.api.forEachNode((rowNode, index) => {
        if (rowNode.data['app_id'] == data['app_id'] && rowNode.data['version'] == data['version']) {
          rowNode.setData(data);
        }
      });
    }
  }

  const StyledTableView = styled.div`
    height: 100% !important;
    .aruba-grid-container {
      height: calc(100% - 122px) !important;
    }
  `;


  const AppStatus = {
    APPROVED_APP: 'APPROVED_APP',
    DRAFT: 'DRAFT',
    PENDING_DRAFT: 'PENDING_DRAFT',
    IN_REVIEW: 'IN_REVIEW',
    REJECTED_DRAFT: 'REJECTED_DRAFT',
    SUSPENDED: 'SUSPENDED'
  }

  const gridFiltersDialogProps = {
    filters: [
      {
        displayName: t('status', { ns: 'common' }),
        field: 'status',
        fieldType: FieldTypesEnum.Enumeration,
        filterParams: {
          enumType: AppStatus,
          selectedValues: pendingRef.current ? Object.keys(AppStatus).slice(2, 4) : null,
          textFormatter: (enumKey, filter) => {
            return t('app_status.' + enumKey, { ns: 'common' })
          }
        }
      }
    ]
  };

  return (
    <>
      <StyledTableView>
        <TableView
          tableName={t('app_page.title')}
          subHeader={<GvtTypography variant="h5">{t('app_page.subtitle')}</GvtTypography>}
          gridConfig={
            {
              viewMode: ViewModeEnum.CompactView,
              fieldsCompactView: [
                {
                  field: 'app_id',
                  displayName: t('id', { ns: 'common' }),
                  sortable: true,
                  link: (rowData) => appRedirectRoute + rowData?.app_id + "/details",
                  onClick: (...args) => console.log('Row Clicked', args)
                },
                {
                  field: 'developer_id',
                  displayName: t('organization', { ns: 'common' }),
                  sortable: true,
                  fieldType: FieldTypesEnum.Text
                },
                {
                  field: 'name',
                  displayName: t('app_name', { ns: 'common' }),
                  sortable: true,
                  fieldType: FieldTypesEnum.Text
                },
                {
                  field: 'version',
                  displayName: t('version', { ns: 'common' }),
                  sortable: true,
                  fieldType: FieldTypesEnum.Text
                },
                {
                  field: 'status',
                  displayName: t('status', { ns: 'common' }),
                  valueFormatter: (params) => {
                    return params && 'status' in params && params.status ? (t('app_status.' + params?.status, { ns: 'common' })) : ''
                  },
                  sortable: true,
                  icon: (rowData) => {
                    const fieldValue = rowData?.status;
                    switch (fieldValue) {
                      case 'SUSPENDED':
                        return IconsEnum.HealthSmallUnknown;
                      case 'APPROVED_APP':
                        return IconsEnum.HealthSmallGood;
                      case 'REJECTED_DRAFT':
                        return IconsEnum.HealthSmallPoor;
                      default:
                        return IconsEnum.HealthSmallFair;
                    }
                  }
                },
                {
                  field: 'updated_at',
                  displayName: t('modified_at', { ns: 'common' }),
                  valueFormatter: (params) => {
                    return params && 'updated_at' in params && params.updated_at ? new Date(params?.updated_at).toLocaleDateString() : '';
                  },
                  sortable: true,
                  fieldType: FieldTypesEnum.Text
                },
                {
                  field: 'created_by',
                  displayName: t('created_by', { ns: 'common' }),
                  sortable: true,
                  fieldType: FieldTypesEnum.Text
                }
              ],
              fieldsComfortView: [],
              fetchGridData: fetchGridData,
              pageSize: 20,
              pageCacheLimit: 2,
              events: {
                onGridReady: (e) => { grid.current = e }
              },
              rowActions: {
                delete: true,
                onAction: (action, rowData) => {
                  if(action.id === "security_report") {
                    setAppId(rowData.app_id);
                    setAppVersion(rowData.version);
                    setModalOpen(true);
                  } else {
                    dialogRef.current.doSetAppAction(action.id, rowData);
                  }
                },
                menuItems: [
                  {
                    label: t("approve", { ns: 'common' }),
                    id: 'approve'
                  },
                  {
                    label: t("reject", { ns: 'common' }),
                    id: 'reject'
                  },
                  {
                    label: t("suspend", { ns: 'common' }),
                    id: 'suspend'
                  },
                  {
                    label: t("security_scan", { ns: 'common' }),
                    id: 'security_report'
                  }
                ]
              }
            }
          }
          columnCustomization={false}
          search={false}
          gridFiltersConfig={gridFiltersDialogProps}
        />

      </StyledTableView>
      
      <Modal ref={dialogRef} updateApp={updateApp} />

      <SecurityDetailsModal 
          open={modalOpen} 
          setModalOpen={setModalOpen}  
          onClose={() => setModalOpen(false)}
          appId={appId}
          appVersion = {appVersion}
      />
    </>
  );
}

export default AppsPage;
