import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { appDetailsRoute, appsInnerRoute, mainRoute } from '../../utils/routes'

import AppsPage from './apps'
import { AppDetailsPage } from './app.details'

const AppsRouter = () => {
  return (
    <Routes>
      <Route path={mainRoute} element={<AppsPage align="start" justify="start" />} />
      <Route path={appDetailsRoute} element={<AppDetailsPage align="start" justify="start" />} />
    </Routes>
  )
}

export default AppsRouter
