import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { StyledColumnLayout, StyledPage } from '../../components/styled-components';
import { useNavigate } from 'react-router-dom';
import { CaptionValue, ColumnLayout, FieldTypesEnum, GvtDrawer, Loading, TableView, ViewModeEnum, px2rem, SupportedColumnLayout, GvtModalDialog, TextArea, GvtTypography, GridFiltersDialog, GridStateProvider, FiltersDialog } from "@gravity/ui-components";
import styled from "styled-components";
import { IconsEnum } from "@gravity/icons";
import { changeOrganizationsStatus, getOrganizations, getOrganizationsExpand } from '../../utils/apis';
import { appsRoute } from '../../utils/routes';
import { usePendingContext } from '../../context/adp-context/context';

export const OrganizationsStatusEnum = {
    TO_DO: 'to_do',
    DONE: 'done',
    REJECTED: 'rejected'
}

const StyledLoader = styled.div`
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  opacity: .75;
`;

const Sidebar = styled.div`
    position: absolute; 
    height: 100%; 
    min-width: ${px2rem(490)};
    right:0; 
    top:0;

    & .MuiDialog-container {
        & .MuiPaper-root { 
            border-radius: 0.666667rem 0px 0px 0.666667rem;
            transition: transform 350ms cubic-bezier(0, 0, 0.2, 1) 0ms; 
            width: ${px2rem(690)};
            padding: 2rem 1.83333rem;
            box-shadow: 0 0.3333333333333333rem 0.6666666666666666rem var(--elevation-medium);
            border-left: 1px solid rgba(0, 0, 0, 0.12);
            box-sizing: content-box;
        }
      }
`;

const StyledTableView = styled.div`
    position: relative;
    height: calc(100% - 80px);
        .aruba-grid-container {
            height: calc(100% - 20px) !important;
        }
`;

const OrganizationStatusModal = (props) => {
    const { t } = useTranslation(['organizations', 'common']);
    const organizationModalFields = {
        reason: ''
    }
    const [organizationModal, setOrganizationModal] = useState({
        [organizationModalFields.reason]: ''
    });
    const [disabled, setDisabled] = useState(true);
    const [commentRequired, setCommentRequired] = useState(false);
    const disabledRef = useRef();

    const onClose = (e, reason) => {
        if(reason !== 'backdropClick') {
            props.onClose(false);
        }
    }

    const getModalDetails = () => {
        switch(props?.actionType) {
            case OrganizationsStatusEnum.DONE:
                disabledRef.current = false;
                return {
                    title: t('organization_status_modal.approve.title', { ns: 'organizations' }),
                    description: t('organization_status_modal.approve.description', { ns: 'organizations' }),
                    children: t("approve", { ns: 'common' })
                }
            case OrganizationsStatusEnum.REJECTED:
                disabledRef.current = true;
                return {
                    title: t('organization_status_modal.reject.title', { ns: 'organizations' }),
                    description: t('organization_status_modal.reject.description', { ns: 'organizations' }),
                    children: t("reject", { ns: 'common' })
                }
        }
    }

    const { title, description, children } = getModalDetails();

    const clearOrganizationModal = (name, value) => {
        setOrganizationModal({
            ...organizationModal,
            [name]: value,
            reason: value.trim()
        });
    }

    const changeFieldValue = (e) => {
        const { name, value } = e.target;
        if(props?.actionType === OrganizationsStatusEnum.REJECTED) {
            setCommentRequired(true);
            setDisabled(value ? false : true);
        }
        if(name === organizationModalFields.reason && value !== organizationModalFields.reason) {
            clearOrganizationModal(name, value);
        } else {
            setOrganizationModal(prevModal => ({
                ...prevModal,
                [name]: value,
                reason: value.trim()
            }));
        }
    }

    const submitOrganizationStatus = (request_key, action, body) => {
        props.setLoading(true);
        changeOrganizationsStatus(request_key, action, body).then(data => {
            console.log(data);
            props.setLoading(false);
            window.location.reload();
        }, error => {
            console.error(error);
            props.setLoading(false);
        })
    }

    const onSubmit = () => {
        try {
            const statusUpdate = props.actionType;
            const reasonValue = organizationModal[organizationModalFields.reason].trim();
            if(reasonValue !== '') {
                const sanitizedReason = { reason: reasonValue };
                submitOrganizationStatus(props?.requestKey, statusUpdate, sanitizedReason);
            } else {
                submitOrganizationStatus(props?.requestKey, statusUpdate, { reason: '' });
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <GvtModalDialog
            disableEscapeKeyDown={true}
            open={props.open}
            title={title}
            description={description}
            maxWidth={false}
            fullWidth={false}
            buttons={[
                {
                    onClick: () => props.setModalOpen(false),
                    children: t('cancel', { ns: "common" })
                },
                {
                   onClick: onSubmit,
                   color: 'primary',
                   children: children,
                   disabled: disabledRef.current && disabled
                }
            ]}
            onClose={(e) => onClose(e)}
        >
            <TextArea 
                required={commentRequired}
                rows={2}
                onChange={changeFieldValue}
                value={organizationModal[organizationModalFields.reason]}
            />
        </GvtModalDialog>
    );
}

const OrganizationsDrawer = (props) => {
    const { t } = useTranslation(['organizations', 'common']);
    const [loading, setLoading] = useState(false);
    const [organizationData, setOrganizationData] = useState({});

    useEffect(() => {
        setLoading(true);
        getOrganizationsExpand(props?.row).then(response => {
            setOrganizationData(response?.data);
            console.log(organizationData);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [props?.row]);

    const getStatusIcon = (input) => {
        switch (input) {
            case OrganizationsStatusEnum.TO_DO:
                return IconsEnum.HealthSmallFair;
            case OrganizationsStatusEnum.DONE:
                return IconsEnum.HealthSmallGood;
            case OrganizationsStatusEnum.REJECTED:
                return IconsEnum.HealthSmallPoor;
        }
    }

    return (
        <>
            {loading && (<StyledLoader><Loading /></StyledLoader>)}
            <StyledColumnLayout>
                <ColumnLayout layout={SupportedColumnLayout.Grid2}>
                    <CaptionValue 
                        caption={t('organization_drawer.ccs_platform_customer_name', { ns: 'organizations' })}
                        primary={organizationData?.ccs_platform_customer_name}
                    />
                    <CaptionValue 
                        caption={t('organization_drawer.user_id', { ns: 'organizations' })}
                        primary={organizationData?.user_id}
                    />
                </ColumnLayout>
                <br />
                <ColumnLayout layout={SupportedColumnLayout.Grid2}>
                    <CaptionValue 
                        caption={t('organization_drawer.request_key', { ns: 'organizations' })}
                        primary={organizationData?.request_key}
                    />
                    <CaptionValue
                        caption={t('organization_drawer.developer_id', { ns: 'organizations' })}
                        primary={organizationData?.developer_id}
                    />
                </ColumnLayout>
                <br />
                <ColumnLayout layout={SupportedColumnLayout.Grid2}>
                    <CaptionValue
                        caption={t('organization_drawer.vertical', { ns: 'organizations' })}
                        primary={organizationData?.vertical}
                    />
                    <CaptionValue
                        caption={t('organization_drawer.integration_types', { ns: 'organizations' })}
                        primary={organizationData?.integration_types}
                    />
                </ColumnLayout>
                <br />
                <ColumnLayout layout={SupportedColumnLayout.Grid2}>
                    <CaptionValue
                        caption={t('organization_drawer.is_aruba_tech_partner', { ns: 'organizations' })}
                        primary={organizationData?.is_aruba_tech_partner}
                    />
                    {organizationData?.is_aruba_tech_partner === 'Yes' && (
                        <CaptionValue
                            caption={t('organization_drawer.business_development_contact', { ns: 'organizations' })}
                            primary={organizationData?.business_development_contact}
                        />
                    )}
                    {organizationData?.is_aruba_tech_partner === 'No' && (
                        <CaptionValue
                            caption={t('organization_drawer.sales_contact', { ns: 'organizations' })}
                            primary={organizationData?.business_development_contact}
                        />
                    )}
                </ColumnLayout>
                <br />
                <ColumnLayout layout={SupportedColumnLayout.Grid2}>
                    <CaptionValue
                        caption={t('organization_drawer.is_joint_aruba_customer', { ns: 'organizations' })}
                        primary={organizationData?.is_joint_aruba_customer}
                    />
                    {organizationData?.is_joint_aruba_customer === 'Yes' && (
                        <CaptionValue
                            caption={t('organization_drawer.joint_customer_names', { ns: 'organizations' })}
                            primary={organizationData?.joint_customer_names}
                        />
                    )}
                </ColumnLayout>
                <br />
                <ColumnLayout layout={SupportedColumnLayout.Grid2}>
                    <CaptionValue
                        caption={t('organization_drawer.email', { ns: 'organizations' })}
                        primary={organizationData?.email}
                    />
                    <CaptionValue
                        caption={t('organization_drawer.phone', { ns: 'organizations' })}
                        primary={organizationData?.phone}
                    />
                </ColumnLayout>
                <br />
                <ColumnLayout layout={SupportedColumnLayout.Grid2}>
                    <CaptionValue
                        caption={t('organization_drawer.status', { ns: 'organizations' })}
                        primary={t(`organizations_status_enum.${OrganizationsStatusEnum[organizationData?.status]}`, { ns: 'organizations' })}
                        icon={getStatusIcon(OrganizationsStatusEnum[organizationData?.status])}
                    />
                    <CaptionValue
                        caption={t('organization_drawer.reason', { ns: 'organizations' })}
                        primary={organizationData?.reason}
                    />
                </ColumnLayout>
            </StyledColumnLayout>
        </>
    )
};

const OrganizationsPage = () => {
    const { t } = useTranslation(['organizations', 'common']);
    const navigate = useNavigate();
    const grid = useRef();
    const [loading, setLoading] = useState(false);
    const drawerContainerRef = useRef();
    const [requestKey, setRequestKey] = useState(null);
    const [drawerTitle, setDrawerTitle] = useState(null);
    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedActionType, setSelectedActionType] = useState(null);
    const { pendingRef } = usePendingContext();

    const fetchGridData = (paginationState, searchValue, quickFilters, sorting, filters) => {
        let parsedSort = {};
        if('fields' in sorting && sorting['fields'].length > 0) {
            for(let i = 0; i < sorting['fields'].length; i++) {
                parsedSort[sorting['fields'][i]['field']] = sorting['fields'][i]['order'].toLowerCase() === 'asc' ? 1 : -1;
            }
        }

        let parsedFilter = {};
        if(pendingRef.current) {
            parsedFilter['status'] = [OrganizationsStatusEnum.TO_DO.toUpperCase()];
        }

        let currentFilter = filters.filters.filters && filters.filters.filters.length >= 1 && 'filters' in filters.filters.filters[0] ? filters.filters.filters[0]['filters'] : filters.filters.filters;
        for (let i = 0; i < currentFilter.length; i++) {
            if (!(currentFilter[i]['field'] in parsedFilter)) {
                parsedFilter[currentFilter[i]['field']] = [];
            }
            parsedFilter[currentFilter[i]['field']].push(currentFilter[i]['value']);
            pendingRef.current = false;
        }

        return getOrganizations({
            pageNumber: (paginationState.offset / paginationState.limit) + 1,
            pageSize: paginationState.limit,
            sort: parsedSort,
            query: parsedFilter
        }).then(
            (response) => {
                if(response.data.total_elements < 0) {
                    navigate(appsRoute)
                }
                return {
                    rows: response.data.content,
                    total: response.data.total_elements,
                    totalFiltered: response.data.number_of_elements
                }
            },
            (error) => {
                console.error(error);
                return {
                    rows: [],
                    total: 0,
                    totalFiltered: 0
                }
            }
        )
    };

    const titleFormatted = (title) => {
        return(
            <span className="h3-small">{t(`${title}`)}</span>
        );
    }

    const gridFiltersDialogProps = {
        filters: [
            {
                displayName: t('status', { ns: 'common' }),
                field: 'status',
                fieldType: FieldTypesEnum.Enumeration,
                filterParams: {
                    enumType: OrganizationsStatusEnum,
                    selectedValues: pendingRef.current ? Object.keys(OrganizationsStatusEnum).slice(0,1) : null,
                    textFormatter: (enumKey, filter) => {
                        return t('organization_status.' + enumKey, { ns: 'common' })
                    }
                }
            }
        ],
    }

    return (
        <StyledPage>
            {loading && (<StyledLoader><Loading /></StyledLoader>)}
            
            <GvtTypography className="title-text h1-small">
                {t('organizations_page.title', { ns: 'organizations' })}
            </GvtTypography>
            <GvtTypography style={{color: "gray"}} variant="h5">
                {t('organizations_page.description', { ns: 'organizations' })}
            </GvtTypography>

            <Sidebar ref={drawerContainerRef} />
            <GvtDrawer
                container={() => drawerContainerRef.current}
                title={titleFormatted(drawerTitle)}
                category={t('organization_drawer.organization', { ns: 'organizations' })}
                buttons={[]}
                anchor='right'
                width="400"
                isOpen={open}
                onClose={(e) => setOpen(false)}
            >
                <OrganizationsDrawer row={requestKey} open={open} setOpen={setOpen} loading={loading} setLoading={setLoading} />
            </GvtDrawer>

            <StyledTableView>
                <TableView 
                    gridConfig={
                        {
                            viewMode: ViewModeEnum.CompactView,
                            fieldsCompactView: [
                                {
                                    field: 'ccs_platform_customer_id',
                                    displayName: t('organizations_table.ccs_platform_customer_id', { ns: 'organizations' }),
                                    sortable: true,
                                    fieldType: FieldTypesEnum.Text,
                                    onClick: (e, rowData) => { 
                                        setRequestKey(rowData.request_key);
                                        setDrawerTitle(rowData.ccs_platform_customer_id);
                                        setOpen(true);
                                    } 
                                },
                                {
                                    field: 'ccs_platform_customer_name',
                                    displayName: t('organizations_table.ccs_platform_customer_name', { ns: 'organizations' }),
                                    sortable: true,
                                    fieldType: FieldTypesEnum.Text
                                },
                                {
                                    field: 'user_id',
                                    displayName: t('organizations_table.user_id', { ns: 'organizations' }),
                                    sortable: true,
                                    fieldType: FieldTypesEnum.Text
                                },
                                {
                                    field: 'vertical',
                                    displayName: t('organizations_table.vertical', { ns: 'organizations' }),
                                    sortable: true,
                                    fieldType: FieldTypesEnum.Text
                                },
                                {
                                    field: 'status',
                                    displayName: t('organizations_table.status', { ns: 'organizations' }),
                                    sortable: true,
                                    valueFormatter: (params = {}) => {
                                        const status = OrganizationsStatusEnum[params?.status];
                                        return t(`organizations_status_enum.${status}`, { ns: 'organizations' })
                                    },
                                    icon: (rowData) => {
                                        var fieldValue = rowData?.status;
                                        switch (fieldValue?.toLowerCase()) {
                                            case OrganizationsStatusEnum.TO_DO:
                                                return IconsEnum.HealthSmallFair;
                                            case OrganizationsStatusEnum.DONE:
                                                return IconsEnum.HealthSmallGood;
                                            case OrganizationsStatusEnum.REJECTED:
                                                return IconsEnum.HealthSmallPoor;
                                        }
                                    }
                                }
                            ],
                            fieldsComfortView: [],
                            fetchGridData: fetchGridData,
                            pageSize: 20,
                            pageCacheLimit: 2,
                            rowActions: {
                                delete: false,
                                onAction: (action, rowData) => {
                                    setLoading(false);
                                    setSelectedActionType(action.id);
                                    setRequestKey(rowData.request_key)
                                    setModalOpen(true);
                                },
                                menuItems: [
                                    {
                                        label: t("approve", { ns: 'common' }),
                                        id: OrganizationsStatusEnum.DONE
                                    },
                                    {
                                        label: t("reject", { ns: 'common' }),
                                        id: OrganizationsStatusEnum.REJECTED
                                    }
                                ]
                            },
                            events: {
                                onGridReady: (e) => {
                                    grid.current = e;
                                }
                            }
                        }
                    }
                    columnCustomization={false}
                    search={false}
                    gridFiltersConfig={gridFiltersDialogProps}
                />
            </StyledTableView>

            {selectedActionType !== null && (
                <OrganizationStatusModal 
                    open={modalOpen}
                    setModalOpen={setModalOpen}
                    onClose={() => setModalOpen(false)}
                    requestKey={requestKey}
                    actionType={selectedActionType}
                    setLoading={setLoading}
                />
            )}
        </StyledPage>
    );
}

export default OrganizationsPage;