import axios from 'axios'

// withCredentials allows cross-site to read cookies
// frontend and authN backend are on different domains
axios.defaults.withCredentials = true

axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
};

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.defaults.paramsSerializer = (params) => {
  let serialized = []
  for (let key in params) {
    serialized.push(key + "=" + encodeURIComponent(JSON.stringify(params[key])))
  }
  return serialized.join("&")
}

const createHeaders = (customHeaders) => {
  const headers = {
    Accept: 'application/json'
  }

  return { ...headers, ...customHeaders }
}

export const get = (
  url,
  params = {},
  customHeaders = undefined
) => {
  const headers = createHeaders(customHeaders)
  return axios.get(url, {headers, params})
}

export const post = (
  url,
  body = {},
  customHeaders = undefined
) => {
  let headers = createHeaders(customHeaders)
  return axios.post(url, body, { headers })
}

export const del = (
  url,
  body = {},
  customHeaders = undefined
) => {
  let headers = createHeaders(customHeaders)
  return axios.request({url: url, data: body, headers: headers, method: 'DELETE'})
}

export const put = (
  url,
  body = {},
  customHeaders = undefined
) => {
  let headers = createHeaders(customHeaders)
  return axios.put(url, body, { headers })
}

export const patch = (
  url,
  body = {},
  customHeaders = undefined
) => {
  let headers = createHeaders(customHeaders)
  return axios.patch(url, body, { headers })
}