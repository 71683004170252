import { useEffect, useRef, useState } from "react";
import { getAppAttributes, changeAppAttributes } from "../../utils/apis";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StyledPage, StyledToggle } from '../../components/styled-components';
import { Loading, GvtCard, GvtCardHeader, CaptionValue, PageLayout, ContextBar, GvtDrawer, px2rem, GvtButtonColorsEnum, DecoratedFormControl, Toggle } from "@gravity/ui-components";
import { Icon, IconsEnum } from "@gravity/icons";
import { useParams } from "react-router-dom";

export const AppAttributesEnum = {
    true: "True",
    false: "False"
}

export const StyledColumnLayout = styled.div`
    margin-bottom:1rem;
    width: 40%;
`;

export const StyledBody = styled.div`
    font-size:1rem;
    position:relative;
    margin-top:-.75rem;
`;

const StyledLoader = styled.div`
  position: absolute;
  z-index: 999999;
  width: 100%;
  height:100%;
  opacity: .75;
`;

const Sidebar = styled.div`
    position: absolute; 
    height: 100%; 
    min-width: ${px2rem(510)};
    right:0; 
    top:0;

    & .MuiDialog-container {
        & .MuiPaper-root { 
            border-radius: 0.666667rem 0px 0px 0.666667rem;
            transition: transform 350ms cubic-bezier(0, 0, 0.2, 1) 0ms; 
            width: ${px2rem(490)};
            padding: 2rem 1.83333rem;
            box-shadow: 0 0.3333333333333333rem 0.6666666666666666rem var(--elevation-medium);
            border-left: 1px solid rgba(0, 0, 0, 0.12);
        }
      }
`;

const AppDetailsPage = () => {
    const { t } = useTranslation(['apps', 'common']);
    const [loading, setLoading] = useState(false);
    const [appAttributesData, setAppAttributesData] = useState(null);
    const [appDetailsData, setAppDetailsData] = useState(null);
    const { id } = useParams();
    const drawerContainerRef = useRef();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isRecommended, setIsRecommended] = useState(false);
    const [isInternal, setIsInternal] = useState(false);
    const [advanced, setAdvanced] = useState(false);
    const [isDefaultApp, setIsDefaultApp] = useState(false);

    useEffect(() => {
        setLoading(true);
        getAppAttributes(id).then(response => {
            console.log(response);
            setAppAttributesData(response.data.attributes);
            setAppDetailsData(response.data);
            setIsRecommended(response.data.attributes.is_recommended);
            setIsInternal(response.data.attributes.isinternal);
            setAdvanced(response.data.attributes.advanced);
            setIsDefaultApp(response.data.attributes.is_default_app);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [id]);

    const onClick = () => {
        setDrawerOpen(!drawerOpen);
        console.log(drawerOpen);
    }

    const onSubmit = () => {
        changeAppAttributes(appDetailsData?.app_id, {
            "developer_id": appDetailsData?.developer_id,
            "attributes": {
                "advanced": advanced,
                "isinternal": isInternal,
                "is_recommended": isRecommended,
                "is_default_app": isDefaultApp
            }
        }, error => {
            console.log(error);
            setLoading(false);
        });
        setLoading(true);
        setTimeout(() => {
            window.location.reload();
        }, 200);
    }

    const drawerButtons = [
        {   
            onClick: () => setDrawerOpen(false),
            children: t('cancel', { ns: "common" })
        },
        {
            onClick: () => onSubmit(),
            children: t('save', { ns: "common" }),
            color: GvtButtonColorsEnum.Primary
        }
    ]

    const changeIsRecommended = (e) => {
        setIsRecommended(e.target.checked);
    } 

    const changeIsInternal = (e) => {
        setIsInternal(e.target.checked);
    }

    const changeAdvanced = (e) => {
        setAdvanced(e.target.checked);
    }

    const changeIsDefaultApp = (e) => {
        setIsDefaultApp(e.target.checked);
    }

    return(
        <>  
            {loading && (<StyledLoader><Loading /></StyledLoader>)}
            <PageLayout>
                <ContextBar 
                    title={appDetailsData?.name}
                    description={appDetailsData?.status}
                />
                <StyledPage>
                    <GvtCard style={{height: "100%", width: "25%"}} CoreEdit="true" edit="true">
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <GvtCardHeader title={t('app_attributes.title', { ns: 'apps' })} />
                            <Icon name={IconsEnum.FormEdit} onClick={onClick} style={{ cursor: 'pointer' }} />
                        </div>
                        <br />
                        <CaptionValue 
                            caption={t('app_attributes.is_recommended')}
                            primary={AppAttributesEnum[appAttributesData?.is_recommended]}
                        />
                        <br />
                        <CaptionValue 
                            caption={t('app_attributes.is_internal')}
                            primary={AppAttributesEnum[appAttributesData?.isinternal]}
                        />
                        <br />
                        <CaptionValue 
                            caption={t('app_attributes.advanced')}
                            primary={AppAttributesEnum[appAttributesData?.advanced]}
                        />
                        <br />
                        <CaptionValue 
                            caption={t('app_attributes.is_default_app')}
                            primary={AppAttributesEnum[appAttributesData?.is_default_app]}
                        />
                    </GvtCard>
                    <Sidebar ref={drawerContainerRef} />
                    <GvtDrawer 
                        container={() => drawerContainerRef.current}
                        isOpen={drawerOpen}
                        onClose={() => setDrawerOpen(false)}
                        title={t('app_attributes.title', { ns: 'apps' })}
                        category={t('app_attributes.drawer.category', { ns: 'apps' })}
                        description={t('app_attributes.drawer.description', { ns: 'apps' })}
                        buttons={drawerButtons}
                    >
                        {loading && (<StyledLoader><Loading /></StyledLoader>)}
                        <StyledColumnLayout>
                            <DecoratedFormControl>
                                <StyledToggle>
                                    <Toggle label={t('app_attributes.is_recommended')} onChange={changeIsRecommended} value={isRecommended} defaultChecked={isRecommended} />
                                </StyledToggle>
                            </DecoratedFormControl>
                            <br />
                            <DecoratedFormControl>
                                <StyledToggle>
                                    <Toggle label={t('app_attributes.is_internal')} onChange={changeIsInternal} value={isInternal} defaultChecked={isInternal}  />
                                </StyledToggle>
                            </DecoratedFormControl>
                            <br />
                            <DecoratedFormControl>
                                <StyledToggle>
                                    <Toggle label={t('app_attributes.advanced')} onChange={changeAdvanced} value={advanced} defaultChecked={advanced}  />
                                </StyledToggle>
                            </DecoratedFormControl>
                            <br />
                            <DecoratedFormControl>
                                <StyledToggle>
                                    <Toggle label={t('app_attributes.is_default_app')} onChange={changeIsDefaultApp} value={isDefaultApp} defaultChecked={isDefaultApp} />
                                </StyledToggle>
                            </DecoratedFormControl>
                        </StyledColumnLayout>
                    </GvtDrawer>
                </StyledPage>
            </PageLayout>
        </>
    );
}

export { AppDetailsPage }