import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardsData } from './card-data';
import { Card } from '../../components/card/Card';
import { useTranslation } from 'react-i18next';
import { PendingApprovalCard } from '../../components/card/Pending-Approval-Card';
import { TitleSubtitle } from '../../components/title-subtitle/TitleSubtitle';
import { getPendingApps, getPendingOrganizations, getPendingRequests } from '../../utils/apis';
import { appsRoute, organizationsRoute, requestsRoute } from '../../utils/routes';
import { ColumnLayout, GvtButton } from '@gravity/ui-components';
import { LocationOrganizationIcon, MiscTicketIcon, NavigationAppsIcon } from '@gravity/icons';
import { usePendingContext } from '../../context/adp-context/context';

function HomePage() {
  const cardsList = CardsData();
  const { t } = useTranslation(['home', 'common']);
  const navigate = useNavigate();
  const [pendingApps, setPendingApps] = useState(null);
  const [pendingOrganizations, setPendingOrganizations] = useState(null);
  const [pendingRequests, setPendingRequests] = useState(null);
  const { pendingRef } = usePendingContext();

  const navigateOnClick = (routeTo, newTab = false) => {
    if (routeTo) {
      if (newTab) {
        window.open(routeTo, '_blank');
      } else {
        navigate(routeTo)
      }
    }
  }

  useEffect(() => {
    if (pendingApps == null) {
      getPendingApps().then(
        (response) => {
          setPendingApps(response.data.total);
        },
        (error) => {
          setPendingApps(0);
          console.error(error)
        });
    }
  }, [pendingApps])

  useEffect(() => {
    if (pendingOrganizations == null) {
      getPendingOrganizations().then(
        (response) => {
          setPendingOrganizations(response.data.total);
        },
        (error) => {
          setPendingOrganizations(0);
          console.error(error)
        });
    }
  }, [pendingOrganizations])

  useEffect(() => {
    if (pendingRequests == null) {
      getPendingRequests().then(
        (response) => {
          setPendingRequests(response.data.total);
        },
        (error) => {
          setPendingRequests(0);
          console.error(error)
        });
    }
  }, [pendingRequests])

  return (
    <>
      <ColumnLayout layout="large">
        <div onClick={() => { pendingRef.current = true; navigateOnClick(organizationsRoute) }}>
          <PendingApprovalCard
            key="1"
            icon={<LocationOrganizationIcon size="large" />}
            testId="pending-organization-card"
            title={t('pending_organization')}
            titleSize="medium"
            approvalCount={pendingOrganizations}
          />
        </div>
        <div onClick={() => { pendingRef.current = true; navigateOnClick(appsRoute) }}>
          <PendingApprovalCard
            key="2"
            icon={<NavigationAppsIcon size="large" />}
            testId="pending-apps-card"
            title={t('pending_apps')}
            titleSize="medium"
            approvalCount={pendingApps}
          />
        </div>
        <div onClick={() => { pendingRef.current = true; navigateOnClick(requestsRoute) }}>
          <PendingApprovalCard
            key="3"
            icon={<MiscTicketIcon size="large" />}
            testId="pending-requests-card"
            title={t('pending_requests')}
            titleSize="medium"
            approvalCount={pendingRequests}
          />
        </div>
      </ColumnLayout>
      <div style={{marginTop: "20px", marginBotton: "10px"}}><TitleSubtitle title={t('administrator_actions')} subtitle={''} testId="titleSubtitleActions" /></div>
      <ColumnLayout layout="large">
      {cardsList.map(
          (cardData) =>
          (
            <Card
              key={cardData.id}
              action={
                <GvtButton
                  size="small"
                  type="button"
                  {...cardData.action.button}
                  onClick={() => { pendingRef.current = false; navigateOnClick(cardData.routeTo, cardData.newTab) }
                  }
                >{cardData.action.label}</GvtButton>
              }
              foregroundColor={cardData.foreground}
              background={cardData.background}
              description={cardData.description}
              icon={<>{cardData.icon}<br /></>}
              testId={cardData.testId}
              title={cardData.title}
              titleSize="xlarge"
            />
          )
        )}
      </ColumnLayout>
    </>
  );
}

export default HomePage;