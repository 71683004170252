import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FieldTypesEnum, GvtDrawer, GvtModalDialog, GvtTypography, Loading, TableView, TextArea, TypographyEnum, ViewModeEnum, px2rem } from "@gravity/ui-components";
import { IconsEnum } from "@gravity/icons";
import styled from "styled-components";
import { changeRequestDeviceClassStatus, changeRequestStatus, getRequestDeviceClassDetails, getRequests } from '../../utils/apis';
import { StyledPage } from '../../components/styled-components';
import { RequestDrawer } from './request.drawer';
import { appsRoute } from '../../utils/routes';
import { usePendingContext } from '../../context/adp-context/context';


export const RequestStatusEnum = {
    TO_DO: 'to_do',
    DONE: 'done',
    REJECTED: 'rejected',
    IN_PROGRESS: 'in_progress',
    AWAITING_USER_ACTION: 'awaiting_user_action',
    COMMENT: 'comment'
};

const RequestFilterEnum = {
    DONE: 'DONE',
    REJECTED: 'REJECTED',
    TO_DO: 'TO_DO',
    IN_PROGRESS: 'IN_PROGRESS',
    AWAITING_USER_ACTION: 'AWAITING_USER_ACTION'
}

const StyledLoader = styled.div`
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  opacity: .75;
`;

const Sidebar = styled.div`
    position: absolute; 
    height: 100%; 
    min-width: ${px2rem(510)};
    right:0; 
    top:0;

    & .MuiDialog-container {
        & .MuiPaper-root { 
            border-radius: 0.666667rem 0px 0px 0.666667rem;
            transition: transform 350ms cubic-bezier(0, 0, 0.2, 1) 0ms; 
            width: ${px2rem(490)};
            padding: 2rem 1.83333rem;
            box-shadow: 0 0.3333333333333333rem 0.6666666666666666rem var(--elevation-medium);
            border-left: 1px solid rgba(0, 0, 0, 0.12);
        }
      }
`;

const StyledTableView = styled.div`
    position: relative;
    height: calc(100% - 80px);
        .aruba-grid-container {
            height: calc(100% - 20px) !important;
        }
`;

const DeviceClassModal = (props) => {
    const { t } = useTranslation(['requests', 'common']);

    const [modalName, setModalName] = useState();
    const [modalDescription, setModalDescription] = useState();
    const [modalReportingIntervals, setModalReportingIntervals] = useState();
    const [deviceClassDetails, setDeviceClassDetails] = useState();

    useEffect(() => {
        if(props.open) {
            getRequestDeviceClassDetails(props?.requestKey).then(response => {
                console.log(response?.data);
                setDeviceClassDetails(response?.data);
                setModalName(response?.data.name)
                setModalDescription(response?.data.description);
                setModalReportingIntervals(response?.data.reporting_intervals);
            }).catch(error => {
                console.error(error);
            })
        }
    }, [props.open]);

    const changeModalName = (e) => {
        const { name, value } = e.target;
        setModalName(value.trim())
    }

    const changeModalDescription = (e) => {
        const { name, value } = e.target;
        setModalDescription(value.trim())
    }

    const changeModalReportingIntervals = (e) => {
        const { name, value } = e.target;
        setModalReportingIntervals(value.trim())
    }

    const onClose = (e, reason) => {
        if(reason !== 'backdropClick') {
            props.onClose(false);
        }
    }

    const onSubmit = () => {
        props.onSubmit(props?.requestKey, props?.actionType, {
            "transition": {
                "id": props?.actionType,
            },
            "name": modalName,
            "description": modalDescription,
            "reporting_intervals": modalReportingIntervals
        });
        onClose();
    }

    return (
        <GvtModalDialog
            disableEscapeKeyDown={true}
            open={props.open}
            title={t('device_class_modal.title', { ns: "requests" })}
            maxWidth={false}
            fullWidth={false}
            buttons={[
                {
                    onClick: () => props.setModalOpen(false),
                    children: t('cancel', { ns: "common" })
                },
                {
                   onClick: onSubmit,
                   color: 'primary',
                   children: t('approve', { ns: "common" })
                }
            ]}
            onClose={(e) => onClose(e)}
        >
            <p className={TypographyEnum.TextBoldDefault}>{t('device_class_modal.name', { ns:  'requests' })}</p>
            <TextArea 
                rows={1}
                required={true}
                onChange={changeModalName}
                value={modalName}
                defaultValue={deviceClassDetails?.name}
                disabled={true}
            />

            <p className={TypographyEnum.TextBoldDefault}>{t('device_class_modal.description', { ns:  'requests' })}</p>
            <TextArea 
                rows={3}
                required={true}
                onChange={changeModalDescription}
                value={modalDescription}
                defaultValue={deviceClassDetails?.description}
            />

            <p className={TypographyEnum.TextBoldDefault}>{t('device_class_modal.reporting_interval', { ns:  'requests' })}</p>
            <TextArea 
                required={true}
                rows={3}
                onChange={changeModalReportingIntervals}
                value={modalReportingIntervals}
                defaultValue={deviceClassDetails?.reporting_intervals}
            />
        </GvtModalDialog>
    );
}

const RequestPage = () => {
    const { t } = useTranslation(['requests', 'common']);
    const navigate = useNavigate();
    const grid = useRef(null);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [actionType, setActionType] = useState(null);
    const [requestKey, setRequestKey] = useState(null);
    const [open, setOpen] = useState(false);
    const [rowData, setRowData] = useState({});
    const drawerContainerRef = useRef();
    const { pendingRef } = usePendingContext();

    const submitRequestStatus = (request_key, status, body) => {
        setLoading(true);
        changeRequestStatus(request_key, status, body).then(data => {
            console.log(data);
            setLoading(false);
            window.location.reload();
        }, error => {
            console.error(error);
            setLoading(false);
        })
    }

    const submitDeviceClassDetails = (request_key, status, body) => {
        setLoading(true);
        changeRequestDeviceClassStatus(request_key, status, body).then(data => {
            console.log(data);
            setLoading(false);
            window.location.reload();
        }, error => {
            console.error(error);
            setLoading(false);
        })
    }

    const getTitleFromatter = () => {
        const title = rowData?.component;
        return t(`requests_drawer.titles.${title}`.toLowerCase(), { ns: 'requests' })
    }

    const fetchGridData = (paginationState, searchValue, quickFilters, sorting, filters) => {
        let parsedSort = {}
        if ('fields' in sorting && sorting['fields'].length > 0) {
            for (let i = 0; i < sorting['fields'].length; i++) {
                parsedSort[sorting['fields'][i]['field']] = sorting['fields'][i]['order'].toLowerCase() === 'asc' ? 1 : -1;
            }
        }

        let parsedFilter = {};
        if(pendingRef.current) {
            parsedFilter['status'] = [RequestFilterEnum.TO_DO];
            parsedFilter['status'].push(RequestFilterEnum.IN_PROGRESS);
            parsedFilter['status'].push(RequestFilterEnum.AWAITING_USER_ACTION);
        }

        let currentFilter = filters.filters.filters && filters.filters.filters.length >= 1 && 'filters' in filters.filters.filters[0] ? filters.filters.filters[0]['filters'] : filters.filters.filters;
        for (let i = 0; i < currentFilter.length; i++) {
            if (!(currentFilter[i]['field'] in parsedFilter)) {
                parsedFilter[currentFilter[i]['field']] = [];
            }
            parsedFilter[currentFilter[i]['field']].push(currentFilter[i]['value'])
            pendingRef.current = false;
        }

        return getRequests({
            pageNumber: (paginationState.offset / paginationState.limit) + 1,
            pageSize: paginationState.limit,
            sort: parsedSort,
            query: parsedFilter
        }).then(
            (response) => {
                if (response.data.total_elements < 0) {
                    navigate(appsRoute)
                }
                return {
                    rows: response.data.content,
                    total: response.data.total_elements,
                    totalFiltered: response.data.number_of_elements
                }
            },
            (error) => {
                console.error(error);
                return {
                    rows: [],
                    total: 0,
                    totalFiltered: 0
                }
            }
        )
    };

    const gridFiltersDialogProps = {
        filters: [
            {
                displayName: t('status', { ns: 'common' }),
                field: 'status',
                fieldType: FieldTypesEnum.Enumeration,
                filterParams: {
                    enumType: RequestFilterEnum,
                    selectedValues: pendingRef.current ? Object.keys(RequestFilterEnum).slice(2, 5) : null,
                    textFormatter: (enumKey, filter) => {
                        return t('request_status.' + enumKey, { ns: 'common' })
                    }
                }
            }
        ]
    };

    return (
        <>
            <StyledPage>
                {loading && (<StyledLoader><Loading /></StyledLoader>)}
                <Sidebar ref={drawerContainerRef} />
                <GvtDrawer
                    title={getTitleFromatter()}
                    category={t('requests_drawer.details', { ns: 'requests' })}
                    container={() => drawerContainerRef.current}
                    buttons={[]}
                    anchor="right"
                    width="400"
                    isOpen={open}
                    onClose={(e) => setOpen(false)}
                >
                    <RequestDrawer row={rowData} open={open} setOpen={setOpen}></RequestDrawer>
                </GvtDrawer>

                <GvtTypography
                    className="title-text h1-small"
                >{t('requests_page.title')}
                </GvtTypography>
                <GvtTypography
                    style={{ color: "gray" }}
                    variant="h5"
                >
                    {t('requests_page.description')}
                </GvtTypography>

                <StyledTableView>

                    <TableView

                        gridConfig={
                            {
                                viewMode: ViewModeEnum.CompactView,
                                fieldsCompactView: [
                                    {
                                        field: 'request_key',
                                        displayName: t('requests_table.request_id', { ns: 'requests' }),
                                        sortable: true,
                                        fieldType: FieldTypesEnum.Text,
                                        onClick: (...args) => console.warn('Cell Clicked', args)
                                    },
                                    {
                                        field: 'component',
                                        displayName: t('requests_table.request_type', { ns: 'requests' }),
                                        sortable: true,
                                        fieldType: FieldTypesEnum.Text,
                                        valueFormatter: (params) => {
                                            return t(`requests_drawer.titles.${params?.component}`.toLowerCase(), { ns: 'requests' })
                                        }
                                    },
                                    {
                                        field: 'summary',
                                        displayName: t('requests_table.description', { ns: 'requests' }),
                                        sortable: true,
                                        fieldType: FieldTypesEnum.Text
                                    },
                                    {
                                        field: 'updated',
                                        displayName: t('requests_table.last_updated', { ns: 'requests' }),
                                        sortable: true,
                                        fieldType: FieldTypesEnum.Text,
                                        valueFormatter: (params) => {
                                            return params && 'updated' in params && params.updated ? new Date(params?.updated).toLocaleString() : '';
                                        }
                                    },
                                    {
                                        field: 'status',
                                        displayName: t('requests_table.status', { ns: 'requests' }),
                                        sortable: true,
                                        valueFormatter: (params = {}) => {
                                            const status = RequestStatusEnum[params?.status];
                                            return t(`requests_status_enum.${status}`, { ns: 'requests' })
                                        },
                                        icon: (rowData) => {
                                            const fieldValue = rowData?.status;
                                            switch (fieldValue?.toLowerCase()) {
                                                case RequestStatusEnum.REJECTED:
                                                    return IconsEnum.HealthSmallPoor;
                                                case RequestStatusEnum.DONE:
                                                    return IconsEnum.HealthSmallGood;
                                                case RequestStatusEnum.TO_DO:
                                                    return IconsEnum.HealthSmallFair;
                                                case RequestStatusEnum.AWAITING_USER_ACTION:
                                                    return IconsEnum.PortStatusSmallDisabled;
                                                default:
                                                    return IconsEnum.HealthSmallUnknown; // in_progress
                                            }
                                        }
                                    }
                                ],
                                fieldsComfortView: [],
                                fetchGridData: fetchGridData,
                                pageSize: 20,
                                pageCacheLimit: 2,
                                rowActions: {
                                    delete: false,
                                    onAction: (action, rowData) => {
                                        console.log('action: ', action.id, rowData);
                                        if(action.id === RequestStatusEnum.COMMENT) {
                                            setRowData(rowData);
                                            setOpen(true);
                                        } 
                                        else if(rowData.component === "DEVICE_CLASS" && action.id === RequestStatusEnum.DONE) {
                                            // Trigger the modal and carry on the action
                                            setActionType(action.id);
                                            setRequestKey(rowData.request_key);
                                            setModalOpen(true);
                                        } 
                                        else {
                                            submitRequestStatus(rowData.request_key, action.id, {
                                                "transition": {
                                                    "id": action.id
                                                }
                                            });
                                        }                                        
                                    },
                                    menuItems: [
                                        {
                                            label: t("approve", { ns: 'common' }),
                                            id: RequestStatusEnum.DONE
                                        },
                                        {
                                            label: t("reject", { ns: 'common' }),
                                            id: RequestStatusEnum.REJECTED
                                        },
                                        {
                                            label: t("comment", { ns: 'common' }),
                                            id: RequestStatusEnum.COMMENT
                                        },
                                        {
                                            label: t("in_progress", { ns: 'common' }),
                                            id: RequestStatusEnum.IN_PROGRESS
                                        },
                                        {
                                            label: t("awaiting_user_action", { ns: 'common' }),
                                            id: RequestStatusEnum.AWAITING_USER_ACTION
                                        }
                                    ]
                                },
                                events: {
                                    onGridReady: (e) => { grid.current = e }
                                }
                            }
                        }

                        columnCustomization={false}
                        search={false}
                        gridFiltersConfig={gridFiltersDialogProps}
                    />
                </StyledTableView>
                
                <DeviceClassModal 
                    open={modalOpen}
                    setModalOpen={setModalOpen}
                    onClose={() => setModalOpen(false)}
                    onSubmit={submitDeviceClassDetails}
                    actionType={actionType}
                    requestKey={requestKey}
                />
            </StyledPage>
        </>
    );
}

export default RequestPage
