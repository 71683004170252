import { Icon, IconsEnum } from "@gravity/icons";
import { CaptionValue, ColumnLayout, DecoratedFormControl, GvtButton, GvtTypography, Loading, SupportedColumnLayout, TextArea } from "@gravity/ui-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getComments, postComments} from '../../utils/apis';
import { RequestStatusEnum } from "./requests";

export const StyledColumnLayout = styled.div`
    margin-bottom:1rem;
`;

export const StyledComments = styled.div`
    min-height:150px;
    overflow-y:scroll;
`

export const StyledCommentsBody = styled.div`
    margin-bottom:0rem;
`;

export const StyledBody = styled.div`
    font-size:1rem;
    position:relative;
    margin-top:-.75rem;
`;

const StyledLoader = styled.div`
  position: absolute;
  z-index: 999999;
  width: 100%;
  height:100%;
  opacity: .75;
`;

const DrawerButtons = styled.div`
    display:flex;
    justify-content: flex-end;
`;


const RequestDrawer = (props) => {
    const { t } = useTranslation(['requests', 'common']);
    const [comments, setComments] = useState([]);
    const [commentValue, setCommentValue] = useState({});
    const [loading, setLoading] = useState(false);
    const [commentCount, setCommentCount] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const getLastUpdated = (params) => {
        return params && 'updated' in params && params.updated ? new Date(params?.updated).toLocaleString() : '';
    }

    const getCommentCreated = (params) => {
        return params && 'created' in params && params.created ? new Date(params?.created).toLocaleString() : '';
    }

    const onComment = (e) => {
        const { name, value } = e.target;
        setDisabled(value ? false : true);
        setCommentValue({
            [name]: value
        })
    }

    const getStatusIcon = (rowData) => {
        const fieldValue = rowData?.status;
        switch (fieldValue?.toLowerCase()) {
            case RequestStatusEnum.REJECTED:
                return IconsEnum.HealthSmallPoor;
            case RequestStatusEnum.DONE:
                return IconsEnum.HealthSmallGood;
            case RequestStatusEnum.TO_DO:
                return IconsEnum.HealthSmallFair;
            default:
                return IconsEnum.HealthSmallUnknown; // in_progress
        }
    }

    useEffect(() => {
        setLoading(true);
        getComments(props?.row?.request_key).then(response => {
            setLoading(false);
            setComments(response.data.content);
            console.log(response.data.content);
        }, error => {
            setLoading(false);
        });
    }, [commentCount, props?.row]);

    const addComment = () => {
        setLoading(true);
        postComments(props?.row?.request_key, commentValue).then(response => {
            // setComments(response.data.content);
            setLoading(false);
            console.log(response.data);
            setCommentValue({
                commentary: ''
            });
            setCommentCount(
                [...commentCount, response.data]
            );
        }, error => {
            setLoading(false);
            console.log(error);
            setCommentValue({
                commentary: ''
            });
        });
        setLoading(true);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    return (
        <>
            {loading && (<StyledLoader><Loading /></StyledLoader>)}
            <StyledColumnLayout>
                <ColumnLayout layout={SupportedColumnLayout.Grid2}>
                    <CaptionValue 
                    caption={t('requests_table.request_id', { ns: 'requests' })} 
                    primary={props?.row?.request_key} 
                />
                    <CaptionValue 
                    caption={t('requests_table.request_type', { ns: 'requests' })} 
                    primary={
                        t(`requests_drawer.titles.${props?.row?.component}`.toLowerCase(), { ns: 'requests' })
                    } />
                </ColumnLayout>
            </StyledColumnLayout>
            <StyledColumnLayout>
                <ColumnLayout layout={SupportedColumnLayout.Grid2}>
                    <CaptionValue caption={t('requests_table.last_updated', { ns: 'requests' })} primary={getLastUpdated(props?.row)} />
                    <CaptionValue caption={t('requests_table.status', { ns: 'requests' })} 
                    primary={
                        t(`requests_status_enum.${RequestStatusEnum[props?.row?.status]}`, { ns: 'requests' })
                    }
                    icon={getStatusIcon(props?.row)}
                    />

                </ColumnLayout>
            </StyledColumnLayout>

            <StyledColumnLayout>
                <ColumnLayout layout={SupportedColumnLayout.Grid1}>
                    <CaptionValue caption={t('requests_table.description', { ns: 'requests' })} primary={props?.row?.summary} />
                </ColumnLayout>
            </StyledColumnLayout>
            
            {props?.row?.component === "DEVICE_CLASS" && (
                <StyledColumnLayout>
                    <ColumnLayout layout={SupportedColumnLayout.Grid1}>
                        <CaptionValue caption={t('requests_table.advertising_interval', { ns: 'requests' })} primary={props?.row?.advertising_interval} />
                    </ColumnLayout>
                </StyledColumnLayout>
            )}

            <GvtTypography
                style={{ color: "black", fontWeight: "bold", marginBottom: "1rem" }}
                variant="h6"
            >
                {t('requests_drawer.comments')}
            </GvtTypography>
            <StyledComments>
                {comments?.map((comment, index) => (
                    <StyledCommentsBody>
                        <ColumnLayout layout={SupportedColumnLayout.Grid1}>
                            <CaptionValue caption={[comment?.author, getCommentCreated(comment)].join(' , ')} primary={` `} />
                        </ColumnLayout>
                        <ColumnLayout layout={SupportedColumnLayout.Grid1}>
                            <StyledBody>
                                <span dangerouslySetInnerHTML={{ __html: comment.body }} ></span>
                            </StyledBody>
                        </ColumnLayout>
                    </StyledCommentsBody>
                ))}
            </StyledComments>

            <DecoratedFormControl label={t('requests_drawer.reply', { ns: 'requests' })}>
                <TextArea name='commentary' onChange={onComment} value={commentValue.value} />
            </DecoratedFormControl>
            <DrawerButtons>
                <GvtButton onClick={() => {props?.setOpen(false)}}>
                    {t('requests_drawer.cancel', { ns: 'requests' })}
                </GvtButton>
                <GvtButton color='primary' onClick={addComment} disabled={disabled}>
                    {t('requests_drawer.comment', { ns: 'requests' })}
                </GvtButton>
            </DrawerButtons>

        </>
    );
};

export { RequestDrawer }