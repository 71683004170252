import React from 'react'
import PropTypes from 'prop-types'
import { GvtTypography } from '@gravity/ui-components'

export const TitleSubtitle = ({
  title,
  subtitle,
  justify,
  testId
}) => {
  return (
    <>
        <>
          <GvtTypography
            variant="h3"
          >
            {title}
          </GvtTypography>
          <GvtTypography
            style={{color: "gray"}}
            variant="h5"
          >
            {subtitle}
          </GvtTypography>
        </>
    </>
  )
}

TitleSubtitle.propTypes = {
  /**
   * Title. This will be bolder than the subtitle.
   * This is mandatory.
   */
  title: PropTypes.string.isRequired,
  /**
   * Subtitle.
   * This is mandatory.
   */
  subtitle: PropTypes.string.isRequired,
  /**
   * This prop will be used for aligning the info
   */
  justify: PropTypes.string,
  /**
   * It will be used for test.
   * This is mandatory.
   */
  testId: PropTypes.string.isRequired
}

TitleSubtitle.defaultProps = {
  justify: 'stretch'
}