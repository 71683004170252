import React from 'react'
import { GvtThemeProvider, LightTheme } from '@gravity/ui-components';

export const Theme = ({ children }) => {
  return (
    <GvtThemeProvider theme={LightTheme}>
      {children}
    </GvtThemeProvider>
  )
}
