import React from 'react';
import { useTranslation } from 'react-i18next';
import { appsRoute, requestsRoute, organizationsRoute } from '../../utils/routes';
import { ControlsObjectGroupIcon, ControlsViewIcon, LocationOrganizationIcon, MiscKeyIcon, MiscTicketIcon, NavigationAppsIcon, UserGroupIcon } from '@gravity/icons';
import { allports, flushOrange, persianGreen, purpleHeart, renoSand } from '../../components/colors';

const CardsData = () => {
  const { t } = useTranslation(['home', 'common'])

  return [
    {
      id: 1,
      icon: <LocationOrganizationIcon size="medium" fontSize="1.5em" fill={allports} />,
      title: t('organization_card.title'),
      description: t('organization_card.description'),
      action: {
        label: t('organization_card.action'),
        button: {
          color: "secondary"
        }
      },
      testId: 'organization-card',
      routeTo: organizationsRoute,
      newTab: false,
      visible: true
    }, {
      id: 2,
      icon: <NavigationAppsIcon size="medium" fontSize="1.5em" />,
      title: t('iot.title'),
      description: t('iot.description'),
      action: {
        label: t('manage_apps', {ns: 'common'}),
        button: {
          color: "secondary"
        }
      },
      testId: 'iot-card',
      routeTo: appsRoute,
      newTab: false,
      visible: true
    }, {
      id: 3,
      icon: <MiscTicketIcon size="medium" fontSize="1.5em" fill={flushOrange}/>,
      title: t('requests.title'),
      description: t('requests.description'),
      action: {
        label: t('requests.action'),
        button: {
          color: "secondary"
        }
      },
      testId: 'requests-card',
      routeTo: requestsRoute,
      newTab: false,
      visible: true
    },  {
      id: 4,
      icon: <MiscKeyIcon size="medium" fontSize="1.5em" fill={purpleHeart}/>,
      title: t('api_credentials.title'),
      description: t('api_credentials.description'),
      action: {
        label: t('api_credentials.action'),
        button: {
          color: "secondary"
        }
      },
      testId: 'api_credentials-card',
      routeTo: '',
      newTab: false,
      visible: true
    },  {
      id: 5,
      icon: <ControlsViewIcon size="medium" fontSize="1.5em" fill={renoSand}/>,
      title: t('audit_trail.title'),
      description: t('audit_trail.description'),
      action: {
        label: t('audit_trail.action'),
        button: {
          color: "secondary"
        }
      },
      testId: 'audit_trail-card',
      routeTo: '',
      newTab: false,
      visible: true
    }, {
      id: 6,
      icon: <UserGroupIcon size="medium" fontSize="1.5em" fill={persianGreen}/>,
      title: t('user.title'),
      description: t('user.description'),
      action: {
        label: t('user.action'),
        button: {
          color: "secondary"
        }
      },
      testId: 'user-card',
      routeTo: '',
      newTab: false,
      visible: true
    }
  ]
}

export { CardsData };