import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdpActions, useAdpContext } from '../context/adp-context';
import { getUser, redirectToLogin } from '../utils/apis';
import { useTranslation } from 'react-i18next';
import { ArubaHeader, Loading, MainContent, PageLayout, RightContent } from '@gravity/ui-components';

function Layout({ children }) {
  const { user } = useAdpContext();
  const { organization, dispatchAdpContext } = useAdpContext();
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const [countUserRequests, setCountUserRequests] = useState(0);

  const onClickMenuItem = (item) => {
    if (item.routeTo) {
      navigate(item.routeTo)
    }
  }

  const doGetUser = () => {
    setCountUserRequests(countUserRequests + 1);
    getUser(
      {}
    ).then(
      (response) => {
        dispatchAdpContext({
          type: AdpActions.SET_USER,
          data: response.data
        })
      },
      (error) => {
        console.error(error)
        redirectToLogin()
      }
    )
  }

  useEffect(() => {
    if (countUserRequests <= 0) {
      doGetUser()
    }
    const intervalId = setInterval(() => {
      doGetUser()
    }, process.env.REACT_APP_USER_GET_INTERVAL)
    return () => clearInterval(intervalId);
  }, [dispatchAdpContext, user])

  return (
    <PageLayout>
      {user.username && (
        <>
          <ArubaHeader brandLabel={'Aruba '} brandSubLabel={' App Administrator Portal'} avatarInitials={'RA'} />
          <MainContent>
            <RightContent>
              {children}
            </RightContent>
          </MainContent>
        </>
      )}
      {(!user.username) && (
        <Loading />
      )}
    </PageLayout>
  );
}

export default Layout;