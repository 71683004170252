export const idRoute = '/:id';

export const mainRoute = '/';
export const homeRoute = '/home';
export const appsRoute = '/apps';
export const appDetailsRoute = '/:id/details';
export const appRedirectRoute = appsRoute + '/';
export const appsInnerRoute = appsRoute + '/*';


export const requestsRoute = '/requests';
export const requestsRouteInnerRoute = requestsRoute + '/*';

export const organizationsRoute = '/organizations';
export const organizationsRouteInnerRoute = organizationsRoute + '/*';


export const formatMutableUrl = (url, values) => {
  for (let i = 0; i < values.length; i++) {
    url = url.replace(values[i].from, values[i].to);
  }
  return url;
}

export const formatMutableUrlId = (url, id) => {
  return formatMutableUrl(url, [{
    from: ':id', to: id
  }])
}