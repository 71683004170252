import React from 'react';
import { Theme } from './components/Theme';
import HomePage from './pages/home/home';
import Layout from './components/layout';
import { AdpContextProvider } from './context/adp-context';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { appsInnerRoute, homeRoute, idRoute, mainRoute, organizationsRouteInnerRoute, requestsRouteInnerRoute } from './utils/routes';
import AppsRouter from './pages/apps/router';
import OrganizationPage from './pages/organizations/organizations';
import RequestPage from './pages/requests/requests';
import { PendingContextProvider } from './context/adp-context/context';

const RequestsRouter = () => {
  return (
    <Routes>
      <Route path={mainRoute} element={<RequestPage align="start" justify="start" />} />
      </Routes>
  )
}

const OrganizationsRouter = () => {
  return (
    <Routes>
      <Route path={mainRoute} element={<OrganizationPage align="start" justify="start" />} />
    </Routes>
  )
}

function App() {
  return (
    <Theme>
      <BrowserRouter>
        <AdpContextProvider>
          <PendingContextProvider>
            <Layout>
              <Routes>
                <Route exact path={mainRoute} element={<HomePage align="start" justify="start" />} />
                <Route path={homeRoute} element={<HomePage align="start" justify="start" />} />
                <Route path={appsInnerRoute} element={<AppsRouter align="start" justify="start" />} />
                <Route element={<HomePage align="start" justify="start" />} />
                <Route path={requestsRouteInnerRoute} element={<RequestsRouter />} />
                <Route path={organizationsRouteInnerRoute}  element={<OrganizationsRouter />} />
              </Routes>
            </Layout>
          </PendingContextProvider>
        </AdpContextProvider>
      </BrowserRouter>
    </Theme>
  );
}

export default App;